









import { Component, Mixins } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { DynamicClassesOnShow } from '~/mixins/dynamic-classes-on-show'
import { CartStep, CartStore } from '~/store/cart'

const CartReview = () => import('~/components/CartReview.vue')
const CartPaymentOptions = () => import('@/components/cart/CartPaymentOptions.vue')
const CartPaymentForm = () => import('@/components/cart/CartPaymentForm.vue')

@Component({
    components: {
        VLoadSpinner,
        CartReview,
        CartPaymentOptions,
        CartPaymentForm
    }
})
export default class CartPage extends Mixins(DynamicClassesOnShow) {
    CartStep = CartStep

    @CartStore.State('cartStep') cartStep: number

    mounted() {
        this.$store.commit('cart/SET_CART_STEP', CartStep.Review)
    }
}
